import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';

import { listLocations } from '@wix/ambassador-table-reservations-v1-location/http';
import { listAreas } from '@wix/ambassador-table-reservations-v1-area/http';
import { getAvailableTimeSlots } from '@wix/ambassador-table-reservations-v1-time-slot/http';
import {
  GetAvailableTimeSlotsResponse,
  GetAvailableTimeSlotsRequest,
} from '@wix/ambassador-table-reservations-v1-time-slot/types';
import {
  ListLocationResponse,
  Location,
} from '@wix/ambassador-table-reservations-v1-location/types';
import {
  ListAreasResponse,
  Area,
} from '@wix/ambassador-table-reservations-v1-area/types';

import model from './model';

async function getBusinessLocations(
  flowAPI: PlatformControllerFlowAPI
): Promise<ListLocationResponse> {
  const res = await flowAPI.httpClient.request(listLocations({}));

  return res.data;
}

async function getTimeSlots(
  flowAPI: PlatformControllerFlowAPI,
  params: GetAvailableTimeSlotsRequest
): Promise<GetAvailableTimeSlotsResponse> {
  const res = await flowAPI.httpClient.request(getAvailableTimeSlots(params));

  return res.data;
}

async function getLocationAreas(
  flowAPI: PlatformControllerFlowAPI
): Promise<ListAreasResponse> {
  const res = await flowAPI.httpClient.request(listAreas({}));

  return res.data;
}

function toJSON(param) {
  return JSON.parse(JSON.stringify(param));
}

const getRange = ({
  start = 0,
  end,
  step = 1,
  size = Math.ceil((end - start) / step) + 1,
}) => Array.from({ length: size }, (_, i) => i * step + start);

function getCurrentUTCDate(date?: string | Date): {
  year: number;
  month: number;
  date: number;
  hours: number;
  minutes: number;
} {
  const _date = new Date(date || new Date());

  return {
    year: _date.getUTCFullYear(),
    month: _date.getUTCMonth(),
    date: _date.getUTCDate(),
    hours: _date.getUTCHours(),
    minutes: _date.getUTCMinutes(),
  };
}

export default model.createController(
  ({ $w, $widget, flowAPI, $bind, initState }) => {
    const currentDateTime = new Date();

    const { state } = initState<{
      locations: Location[];
      areas: Area[];
      currentAreaId?: string;
      currentLocationId?: string;
      currentPartySize?: number;
      currentDate: Date;
      currentTime: string;
    }>({
      locations: [],
      areas: [],
      currentAreaId: undefined,
      currentPartySize: undefined,
      currentLocationId: undefined,
      currentDate: currentDateTime,
      currentTime: `${currentDateTime.getUTCHours()}:${
        currentDateTime.getUTCMinutes() < 10 ||
        currentDateTime.getUTCMinutes() === 0
          ? '0' + currentDateTime.getUTCMinutes()
          : currentDateTime.getUTCMinutes()
      }`,
    });

    const pageReady = async () => {
      $widget.fireEvent('widgetLoaded', { a: 'test' });

      const [locations, areas] = await Promise.all([
        getBusinessLocations(flowAPI),
        getLocationAreas(flowAPI),
      ]);

      state.locations = locations.location || [];
      state.areas = areas.area || [];

      // locations dropdown
      $bind('#dropdown1', {
        options: () =>
          state.locations.map((loc) => {
            return { label: loc.name, value: loc.id };
          }),
        selectedIndex: () => 0,
        onChange: (event) => {
          state.currentLocationId = event.target.value;
          state.currentAreaId = state.areas.find(area => area.locationId === event.target.value)?.id;
        },
      });

      // areas dropdown
      $bind('#dropdown2', {
        options: () => {
          const area = state.areas[0];

          const range = getRange({
            start: area.partiesSize?.min,
            end: area.partiesSize?.max,
          });

          return range.map((i) => {
            return {
              label: flowAPI.translations.t('find-table.parties-size', {
                count: i,
              }),
              value: i.toString(),
              raw: state.areas[0],
            };
          });
        },
        selectedIndex: () => 0,
        onChange: (event) => {
          state.currentPartySize = event.target.value;
        },
      });

      // date datepicker
      $bind('#datePicker1', {
        onChange: (event) => {
          const _date = getCurrentUTCDate(event.target.value);
          console.log(_date);
          state.currentDate = event.target.value;
        },
        minDate: () => new Date(state.currentDate),
        value: () => new Date(state.currentDate),
      });

      // time timepicker
      $bind('#timePicker1', {
        onChange: (event) => {
          state.currentTime = event.target.value;
        },
        value: () => `${state.currentTime}`,
      });

      state.currentPartySize = parseInt($w('#dropdown2').value, 10);
      state.currentAreaId = state.areas[0].id ?? '';

      $bind('#button1', {
        onClick: async () => {
          console.log(toJSON(state));
          const slots = await getTimeSlots(flowAPI, {
            areaId: toJSON(state.currentAreaId),
            date: new Date(toJSON(state.currentDate)),
            partySize: toJSON(state.currentPartySize),
            slotsBefore: 7,
            slotsAfter: 7,
          });

          flowAPI.controllerConfig.platformAPIs.storage.local.setItem(
            'tmp_slot',
            JSON.stringify(slots)
          );

          flowAPI.controllerConfig.wixCodeApi.location.to(
            `/time-slots?areaId=${toJSON(state.currentAreaId)}&date=${new Date(
              toJSON(state.currentDate)
            ).toString()}&partySize=${toJSON(state.currentPartySize)}`
          );
        },
      });
    };
    return {
      pageReady,
      exports: {},
    };
  }
);
