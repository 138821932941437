
  import {createControllersWithDescriptors, initAppForPageWrapper} from '@wix/yoshi-flow-editor/runtime/esm/viewerScript.js';
  
  
            
const wrapController0 = null;

            import controller0 from '/home/builduser/work/ef2ef6eb0dd35b26/packages/table-reservations-bob/src/components/TimeSlots/viewer.controller.ts';
            import * as _controllerExport0 from '/home/builduser/work/ef2ef6eb0dd35b26/packages/table-reservations-bob/src/components/TimeSlots/viewer.controller.ts';
            var controllerExport0 = _controllerExport0;
            

            
const wrapController1 = null;

            import controller1 from '/home/builduser/work/ef2ef6eb0dd35b26/packages/table-reservations-bob/src/components/Widget1/viewer.controller.ts';
            import * as _controllerExport1 from '/home/builduser/work/ef2ef6eb0dd35b26/packages/table-reservations-bob/src/components/Widget1/viewer.controller.ts';
            var controllerExport1 = _controllerExport1;
            

  
  var importedApp = {};


  

    import { blocksControllerService } from '@wix/yoshi-flow-editor/runtime/esm/blocksControllerService'
    

  
var createHttpClient = null;

  

  import { initI18n as initI18n } from '@wix/yoshi-flow-editor/runtime/esm/i18next/init';


  

    var createExperiments = null;
    var createWidgetExperiments = null;
    


  var sentryConfig = {
      DSN: 'https://d7a327cdc815438eb104a34ea53f1165@sentry.wixpress.com/2818',
      id: 'd7a327cdc815438eb104a34ea53f1165',
      projectName: 'table-reservations-bob',
      teamName: 'table-reservations-web',
      
    };

  var experimentsConfig = {"centralized":true,"scopes":[]};

  var translationsConfig = {"icuEnabled":true,"defaultTranslationsPath":"/home/builduser/work/ef2ef6eb0dd35b26/packages/table-reservations-bob/src/assets/locales/messages_en.json","availableLanguages":["en","uk"]};

  var defaultTranslations = {"app.widget.welcome":"Welcome","app.settings.label":"Configure Widget","app.settings.tabs.main":"Main","app.settings.tabs.design":"Design","app.settings.defaults.greetingsPretext":"to","find-table.parties-size":"{count} people","pages-menu.find-table":"Find a Table","pages-menu.time-slots":"Time Slots"};

  var fedopsConfig = null;

  import { createVisitorBILogger as biLogger } from '/home/builduser/work/ef2ef6eb0dd35b26/packages/table-reservations-bob/target/generated/bi/createBILogger.ts';

  export const exports = importedApp.exports;

  export const initAppForPage = initAppForPageWrapper({
    initAppForPage: importedApp.initAppForPage,
    sentryConfig: sentryConfig,
    experimentsConfig: experimentsConfig,
    inEditor: false,
    biLogger: biLogger,
    projectName: "table-reservations-bob",
    biConfig: null,
    appName: "Table Reservations",
    appDefinitionId: "a27d22ee-5c11-4947-983e-f9effb541af8",
    fedopsConfig: fedopsConfig,
    translationsConfig: translationsConfig,
    defaultTranslations: defaultTranslations,
    shouldUseEssentials: true,
    optionalDeps: {
      initI18n,
      createHttpClient,
      createExperiments,
    },
    localeDistPath: "assets/locales",
  });

  const _createControllers = createControllersWithDescriptors({
    initI18n,
    blocksControllerService,
    createHttpClient,
    createExperiments,
  }, [{ method: controller0,
          wrap: wrapController0,
          exports: controllerExport0,
          widgetType: "BLOCKS_WIDGET",
          translationsConfig: translationsConfig,
          experimentsConfig: {"centralized":true,"scopes":[]},
          fedopsConfig: fedopsConfig,
          sentryConfig: sentryConfig,
          persistentAcrossPages: false,
          biLogger: biLogger,
          shouldUseEssentials: true,
          withErrorBoundary: false,
          biConfig: null,
          controllerFileName: "/home/builduser/work/ef2ef6eb0dd35b26/packages/table-reservations-bob/src/components/TimeSlots/viewer.controller.ts",
          appName: "Table Reservations",
          appDefinitionId: "a27d22ee-5c11-4947-983e-f9effb541af8",
          projectName: "table-reservations-bob",
          componentName: "TimeSlots",
          localeDistPath: "assets/locales",
          defaultTranslations: defaultTranslations,
          id: "a27d22ee-5c11-4947-983e-f9effb541af8-z5i7l" }, { method: controller1,
          wrap: wrapController1,
          exports: controllerExport1,
          widgetType: "BLOCKS_WIDGET",
          translationsConfig: translationsConfig,
          experimentsConfig: {"centralized":true,"scopes":[]},
          fedopsConfig: fedopsConfig,
          sentryConfig: sentryConfig,
          persistentAcrossPages: false,
          biLogger: biLogger,
          shouldUseEssentials: true,
          withErrorBoundary: false,
          biConfig: null,
          controllerFileName: "/home/builduser/work/ef2ef6eb0dd35b26/packages/table-reservations-bob/src/components/Widget1/viewer.controller.ts",
          appName: "Table Reservations",
          appDefinitionId: "a27d22ee-5c11-4947-983e-f9effb541af8",
          projectName: "table-reservations-bob",
          componentName: "Widget1",
          localeDistPath: "assets/locales",
          defaultTranslations: defaultTranslations,
          id: "a27d22ee-5c11-4947-983e-f9effb541af8-c4xq3" }],
    false);

    export const createControllers = _createControllers
